// sanityClient.js
import sanityClient from '@sanity/client';
import { createClient } from '@sanity/client';
export default createClient({
  projectId: 'gzg44yme',
  dataset: 'production',
  useCdn: true,
  apiVersion: '2021-08-31', // use a UTC date string


});
