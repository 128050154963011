import React, { useEffect, useState } from 'react';
import './Navbar.css'; // Import the CSS file for styling
import { NavLink } from 'react-router-dom';
import dd from "./imgs/dd.png";
import cross from "./imgs/cross.png"
import initializeGoogleAnalytics from './ExampleAnalytics'; // Import the utility function

const Navbar = () => {
  const [showNavbar, setShowNavbar] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);

  useEffect(() => {
    initializeGoogleAnalytics();
    const timeout = setTimeout(() => {
      setShowNavbar(true);
    },  100);
    return () => clearTimeout(timeout);
  }, []);

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const scrollTo = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <nav className={`navbar ${showNavbar ? 'show' : ''}`}>
      <div className="logo">
          <button onClick={() => scrollTo('home')} className="None">
          <h1 className="logo">
            <span className="brand">Pixen</span>
            <span className="highlight">X</span>
           </h1>
          </button>

      </div>
      <div className="menu">
        <ul>
          <li>
            <button onClick={() => scrollTo('services')} className="None">
              Services
            </button>
          </li>
          <li>
            <NavLink to="/ViewMoreProjects">
              Projects
            </NavLink>
          </li>
          <li>
            <button onClick={() => scrollTo('team')} className="None">
              Team
            </button>
          </li>
          <li>
              <NavLink to="/blog">
                Blog
              </NavLink>
          </li>
        </ul>
      </div>
      <button className="demo-btn" onClick={() => scrollTo('contact')}>
        Demo
      </button>
      <div className="Dropdown-icon" onClick={toggleDropdown}>
        {showDropdown ? (
          <img src={cross} alt="Cross Icon" className="cross" />
        ) : (
          <img src={dd} alt="Dropdown Icon" className="dd"/>
        )}
        {showDropdown && (
          <ul className="dropdown-menu">
            <li>
              <NavLink to="/ViewMoreProjects">Projects</NavLink>
            </li>
            <li><NavLink to="/blog">Blog</NavLink></li>
          </ul>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
