import React, { useEffect, useState } from 'react';
import './Update.css';
import sanityClient from './Sanity.js';

function Update() {
  const [updateData, setUpdateData] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch('*[_type == "Update"]{updateText, updateBtn, updateLink}[0]')
      .then((data) => setUpdateData(data))
      .catch(console.error);
  }, []);

  if (!updateData) {
    return <div></div>;
  }

  return (
    <div className="UpdateContainer">
      <div className="Update-Text">{updateData.updateText}</div>
      <div className="Update-Link">
        <button className="Update-Btn" onClick={() => window.location.href = updateData.updateLink}>
          {updateData.updateBtn}
        </button>
      </div>
    </div>
  );
}

export default Update;