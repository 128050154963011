import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import createClient from './Sanity.js';
import { motion, AnimatePresence } from 'framer-motion';
import "./ViewMoreProjects.css";
import ReactGA from 'react-ga';
import initializeGoogleAnalytics from './ExampleAnalytics'; 
import { Helmet } from 'react-helmet';
function ViewMoreProjects() {
  const [showNavbar, setShowNavbar] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [selectedOption, setSelectedOption] = useState('WD');
  const [sanityData, setSanityData] = useState([]);

  const handleWhatsAppClick = () => {
    const phoneNumber = '+919596575743';
    const message = encodeURIComponent("Hi PixenX team! 👋 I am Interested in a 15-min demo call to explore your stellar web, app, and ad services. 🌐📱💡 Let's discuss how you can amplify my online presence. Ready when you are! 🚀 #DigitalInnovation #PixenXDemo");
    const whatsappURL = `https://wa.me/${phoneNumber}?text=${message}`;
    window.open(whatsappURL, '_blank');
  };
  

  useEffect(() => {
    initializeGoogleAnalytics();
    ReactGA.pageview('/ViewMoreProjects'); // Track page view for ViewMoreProjects
  }, []);
  useEffect(() => {
    createClient
    .fetch(`*[_type == "post" && category == "${selectedOption}"] { visitButton,link, title, mainImage {asset->{ _id, url },alt }}`)
      .then((data) => setSanityData(data))
      .catch((error) => console.error('Error fetching Sanity data:', error));
  }, [selectedOption]);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowNavbar(true);
      setIsLoaded(true); // Set isLoaded to true after the timeout
    }, 100);

    return () => clearTimeout(timeout);
  }, []);

  const handleDropdownChange = (option) => {
    setSelectedOption(option);
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { duration: 0.7 } },
    exit: { opacity: 0, transition: { duration: 0.7 } },
  };

  const slideLeft = {
    hidden: { x: '100%' },
    visible: { x: 0, transition: { duration: 0.7 } },
    exit: { x: '-100%', transition: { duration: 0.7 } },
  };

  const slideRight = {
    hidden: { x: '-100%' },
    visible: { x: 0, transition: { duration: 0.7 } },
    exit: { x: '100%', transition: { duration: 0.7 } },
  };

  useEffect(() => {
    console.log("Sanity Data:", sanityData);
  }, [sanityData]);


  ///seo purposes 
  const categoryMeta = {
    WD: {
      title: 'Website Development Projects - PixenX',
      description: 'Discover our range of innovative website development projects.'
    },
    GD: {
      title: 'Graphic Design Projects - PixenX',
      description: 'Explore our creative graphic design projects.'
    },
    DM: {
      title: 'Digital Marketing Projects - PixenX',
      description: 'Learn about our impactful digital marketing campaigns.'
    },
    VE: {
      title: 'Video Editing Projects - PixenX',
      description: 'See our collection of professional video editing works.'
    },
    CWW: {
      title: 'Companies We Have Worked With - PixenX',
      description: 'Meet the companies we have successfully collaborated with.'
    },
    GBP: {
      title: 'Google Business Profiles - PixenX',
      description: 'Check out our expertly crafted Google Business Profiles.'
    },
    CB: {
      title: 'Company Banners - PixenX',
      description: 'View our stunning collection of company banners.'
    },
 }
 //seo 


  return (
    <div className="VMP-Container" id="ViewMoreProjects">
       <Helmet>
        <title>{categoryMeta[selectedOption].title}</title>
        <meta name="description" content={categoryMeta[selectedOption].description} />
      </Helmet>
      <nav className={`navbar ${showNavbar ? 'show' : ''}`}>
        <h1><div className="logo">
          <NavLink to="/">
            <span className="brand">Pixen</span>
            <span className="highlight">X</span>
          </NavLink>
        </div></h1>
        <div className="menud">
          <ul>
          </ul>
        </div>
        <NavLink to="/demo">
          <button className="demo-btnd">Demo</button>
        </NavLink>
      </nav>

      <div className="VMP-ExceptNav">
        <div className="VMP-Header">
          <h1 className="VMP-Header">
          Our Projects
          </h1>
        </div>

        <div className="VMP-dropdown_container">
          <div className="VMP-Choose">Choose Category</div>
          <select
            className="VMP-dropdown-div"
            value={selectedOption}
            onChange={(e) => handleDropdownChange(e.target.value)}
          >
            <option value="WD">Website Development </option>
            <option value="GD">Graphic Design </option>
            <option value="DM">Digital Marketing</option>
            <option value="VE">Video Editing</option>
            <option value="CWW">Companies Worked With</option>
            <option value="GBP">Google Business Profiles</option>
            <option value="CB">Company Banners</option>
          </select>
        </div>

        <AnimatePresence exitBeforeEnter={false}>
          <motion.div
            key={selectedOption}
            variants={selectedOption === 'WD' ? slideLeft : slideRight}
            initial="hidden"
            animate="visible"
            exit="exit"
            transition={{ duration: 0.7 }}
          >
            {selectedOption === 'WD' && <div className="VMP-WD">
              <div className="VMP-Name">Website-Development</div>
              <div className="VM-Projects-Collection">
                {sanityData.map((post) => (
                  <div key={post._id} className="VM-project-item">
                    <div className="VM-Project-Card">
                      <div className="VM-logo-Projects">
                      <img src={post.mainImage.asset.url} alt={post.title} className="VM-img" />     
                      {post.visitButton && (
                          <button className="VM-visit" onClick={() => window.location.href = post.link}>
                            Visit
                          </button>
                        )}
               </div>
                </div>
                   <p>{post.title}</p>
                  </div>
                ))}
              </div>
              <button className="Trap" onClick={handleWhatsAppClick} >Grow Your Business </button>
            </div>}
            {selectedOption === 'GD' && <div className="VMP-GD">
              <div className="VMP-Name">Graphic-Design</div>
              <div className="VM-Projects-Collection">
                {sanityData.map((post) => (
                  <div key={post._id} className="VM-project-item">
                    <div className="VM-Project-Card">
                      <div className="VM-logo-Projects">
              <img src={post.mainImage.asset.url} alt={post.title} className="VM-img" />     
              {post.visitButton && (
                          <button className="VM-visit" onClick={() => window.location.href = post.link}>
                            Visit
                          </button>
                        )}             
              
               </div>
                </div>
                   <p>{post.title}</p>
                  </div>
                ))}
              </div>
              <button className="Trap" onClick={handleWhatsAppClick}> Skyrocket Your Business </button>
            </div>}
            {selectedOption === 'CWW' && <div className="VMP-CWW">
              <div className="VMP-Name">Companies We have worked with</div>
              <div className="VM-Projects-Collection">
                {sanityData.map((post) => (
                  <div key={post._id} className="VM-project-item">
                    <div className="VM-Project-Card">
                      <div className="VM-logo-Projects">
              <img src={post.mainImage.asset.url} alt={post.title} className="VM-img" />     
              {post.visitButton && (
                          <button className="VM-visit" onClick={() => window.location.href = post.link}>
                            Visit
                          </button>
                        )}                          
               </div>
                </div>
                   <p>{post.title}</p>
                  </div>
                ))}
              </div>
              <button className="Trap" onClick={handleWhatsAppClick} >Grow Your Business </button>
            </div>}
  
            {selectedOption === 'DM' && <div className="VMP-DM">
              <div className="VMP-Name">Digital-Marketing</div>
              <div className="VM-Projects-Collection">
                {sanityData.map((post) => (
                  <div key={post._id} className="VM-project-item">
                    <div className="VM-Project-Card">
                      <div className="VM-logo-Projects">
              <img src={post.mainImage.asset.url} alt={post.title} className="VM-img" />     
              {post.visitButton && (
                          <button className="VM-visit" onClick={() => window.location.href = post.link}>
                            Visit
                          </button>
                        )}                          
               </div>
                </div>
                   <p>{post.title}</p>
                  </div>
                ))}
              </div>
              <button className="Trap"  onClick={handleWhatsAppClick}>Advertise Your Business </button>
            </div>}
            {selectedOption === 'VE' && <div className="VMP-VE">
              <div className="VMP-Name">Video Editing</div>
              <div className="VM-Projects-Collection">
                {sanityData.map((post) => (
                  <div key={post._id} className="VM-project-item">
                    <div className="VM-Project-Card">
                      <div className="VM-logo-Projects">
              <img src={post.mainImage.asset.url} alt={post.title} className="VM-img" />     
              {post.visitButton && (
                          <button className="VM-visit" onClick={() => window.location.href = post.link}>
                            Visit
                          </button>
                        )}                          
               </div>
                </div>
                   <p>{post.title}</p>
                  </div>
                ))}
              </div>
              <button className="Trap" onClick={handleWhatsAppClick} > Digitalise Your Business </button>
            </div>}
            {selectedOption === 'CB' && <div className="VMP-CWW">
              <div className="VMP-Name">Company Banners</div>
              <div className="VM-Projects-Collection">
                {sanityData.map((post) => (
                  <div key={post._id} className="VM-project-item">
                    <div className="VM-Project-Card">
                      <div className="VM-logo-Projects">
              <img src={post.mainImage.asset.url} alt={post.title} className="VM-img" />     
              {post.visitButton && (
                          <button className="VM-visit" onClick={() => window.location.href = post.link}>
                            Visit
                          </button>
                        )}                          
               </div>
                </div>
                   <p>{post.title}</p>
                  </div>
                ))}
              </div>
              <button className="Trap"  onClick={handleWhatsAppClick} > Improve Your Business </button>
            </div>}
            {selectedOption === 'GBP' && <div className="VMP-CWW">
              <div className="VMP-Name">Google Business Profiles</div>
              <div className="VM-Projects-Collection">
                {sanityData.map((post) => (
                  <div key={post._id} className="VM-project-item">
                    <div className="VM-Project-Card">
                      <div className="VM-logo-Projects">
              <img src={post.mainImage.asset.url} alt={post.title} className="VM-img" />     
              {post.visitButton && (
                          <button className="VM-visit" onClick={() => window.location.href = post.link}>
                            Visit
                          </button>
                        )}                          
               </div>
                </div>
                   <p>{post.title}</p>
                  </div>
                ))}
              </div>
              <button className="Trap"  onClick={handleWhatsAppClick} > Skyrocket Your Business </button>
            </div>}
          

          </motion.div>
        </AnimatePresence>
      </div>
    </div>
  );
}

export default ViewMoreProjects;
