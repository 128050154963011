import React, { useEffect, useRef, useState } from 'react';
import './Demo.css'; // Import your CSS file for styling
import WhatsAppLogo from './imgs/wa.jpg';
import { NavLink } from 'react-router-dom';

function Demo() {
  const contactRef = useRef(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [brandName, setBrandName] = useState('');
  const [contactNo, setContactNo] = useState('');
  const [showPopup, setShowPopup] = useState(false);

  const [showNavbar, setShowNavbar] = useState(false);

  

  const handleWhatsAppClick = () => {
    const phoneNumber = '+919596575743';
    const message = encodeURIComponent("Hi PixenX team! 👋I am Interested in a 15-min demo call to explore your stellar web, app, and ad services. 🌐📱💡 Let's discuss how you can amplify my online presence. Ready when you are! 🚀 #DigitalInnovation #PixenXDemo");
    const whatsappURL = `https://wa.me/${phoneNumber}?text=${message}`;
    window.open(whatsappURL, '_blank');
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowNavbar(true);
      setIsLoaded(true); // Set isLoaded to true after the timeout
    }, 100);

    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {

    
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setIsLoaded(true);
        }
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.5,
      }
    );

    if (contactRef.current) {
      observer.observe(contactRef.current);
    }

    return () => {
      if (contactRef.current) {
        observer.unobserve(contactRef.current);
      }
    };
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Replace this URL with your backend URL for form submission
    const url = 'https://script.google.com/macros/s/AKfycbyYyK8-sAZNmmghfSn89j2WEaGG0jAq3yGV5Xhz89S-sKfg7i_FkSl9WpAxh-C4LKGy/exec';

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({
          'Brand-Name': brandName,
          'ContactNo': contactNo,
        }).toString(),
      });

      if (response.ok) {
        setShowPopup(true);
      } else {
        console.error('Error submitting form:', response.statusText);
      }
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  return (
    <div className="d-contact" id="contact">
              <nav className="navd">
        <div className="logod">
          <NavLink to="/">
            <span className="brand">Pixen</span>
            <span className="highlight">X</span>
          </NavLink>
        </div>
        <div className="menud">
          <ul>
            <li>
              <NavLink to="/">Home</NavLink>
            </li>
          </ul>
        </div>
        {/** onclick go to / and then scroll to contact */}
             <NavLink to="/">
              <button className="demo-btn">Home</button>
            </NavLink>
      </nav>
      
      <div className="dcenter"> 
        <div
          ref={contactRef}
          className={`contact-container ${isLoaded ? 'loaded' : ''}`}
         >   
           <div className="d-contact-content">
               <div className="d-left-section">
                      <p className="d-bold-text">
                      Book a 15 min Free <br /> Demo Call
                       </p>
                       <p className="d-line">
                      We'll take the work out of elevating your virtual experiences
                      </p>
                     <button className="d-whatsapp-btn" onClick={handleWhatsAppClick}>
                       <p className="d-BtnText">Contact Us on WhatsApp </p>
                       <img src={WhatsAppLogo} alt="WhatsApp Logo" />
                      </button>
                 </div>
               <div className="d-right-section">
                  <form onSubmit={handleSubmit}>
                    <label>
                    <input
                        className="d-input"
                        type="text"
                         placeholder="Brand Name"
                        value={brandName}
                         onChange={(e) => setBrandName(e.target.value)}
                      />
                    </label>
                     <label>
                      <input
                        type="tel"
                      className="d-input"
                        placeholder="Phone No."
                          value={contactNo}
                          onChange={(e) => setContactNo(e.target.value)}
                         />
                      </label>
                       <button type="d-submit" className="submit-btn">
                          Submit
                      </button>
                    </form>
                </div>  
              </div>
           </div>    
         </div>

      {showPopup && (
        <div className="d-popup">
          <div className="d-popup-content">
             
        
            <span className="d-close" onClick={() => setShowPopup(false)}>
               &times;
             </span><br/>
             <p style={{ color: 'black' }}>
              Form submitted successfully!<br /> Our team will contact you shortly.<br />
              <button className="d-wabtn" onClick={handleWhatsAppClick}>Contact Us on Whatsapp</button>
            </p>
          </div>
        </div>
      )}
    </div>
  );
}

export default Demo;
