import React, { useEffect } from 'react'
import "./Chatbot.css"
const Chatbot = () => {
  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://cdn.botpress.cloud/webchat/v1/inject.js'
    script.async = true
    document.body.appendChild(script)
 
    script.onload = () => {
      window.botpressWebChat.init({
         botId: "f9057068-3304-42a1-81d2-a9890f89cd5d",
      hostUrl: "https://cdn.botpress.cloud/webchat/v1",
      messagingUrl: "https://messaging.botpress.cloud",
      clientId: "f9057068-3304-42a1-81d2-a9890f89cd5d",

      })
    }
  }, [])
 
  return <div id="webchat" />
}
 
export default Chatbot