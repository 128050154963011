import React, { useEffect, useState } from 'react';
import './Journey.css';
import Image from './imgs/logo.png';
import customer from "./imgs/customer.jpeg"
import meet from "./imgs/meet.jpeg"
import meet2 from "./imgs/meet2.jpeg"

function Journey() {
  const [activeStep, setActiveStep] = useState(null);

  const scrollTo = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setActiveStep(entry.target.id);
          }
        });
      },
      {
        rootMargin: '0px',
        threshold:  0.5, // Adjust this value to change when the animation triggers
      }
    );

    const stepElements = document.querySelectorAll('.Step-J');
    stepElements.forEach((step) => observer.observe(step));

    return () => {
      stepElements.forEach((step) => observer.unobserve(step));
    };
  }, []);

  return (
    <div className="Journey">
      <div className="Heading-J">Your Journey With Us</div>
      <div className="Content-J">
        <div className="Steps-J">
         <div id="step1" className={`StepOne Step-J ${activeStep === 'step1' ? 'active blue-shadow' : ''}`}>
            <div className="Text-J">
              <h2 className="StepHeading">Step  1 : Reach Out </h2>
              <h4>Contact us to begin your digital journey</h4>
            </div>
            <button className="FormButton-J"  onClick={() => scrollTo('contact')}>Go to Form</button>
          </div>
          <div id="step2" className={`StepTwo Step-J ${activeStep === 'step2' ? 'active red-shadow' : ''}`}>
            <div className="Text-J">
              <h2 className="StepHeading">Step  2 : Talk to Us </h2>
              <h4> Schedule a meeting to discuss your needs and discover our services.</h4>
            </div>
            <div className="StepImagesContainer-J">
            <img src={meet2} alt="" className="StepImages-J"/>
            </div>
          </div>
          <div id="step3" className={`StepThree Step-J ${activeStep === 'step3' ? 'active blue-shadow' : ''}`}>
            <div className="Text-J">
              <h2 className="StepHeading">Step  3 : Create Together </h2>
              <h4> Collaborate with our team to bring your ideas to life.</h4>
            </div>
            <div className="StepImagesContainer-J">
            <img src={meet} alt="" className="StepImages-J"/>
            </div>
          </div>
          <div id="step4" className={`StepFour Step-J ${activeStep === 'step4' ? 'active red-shadow' : ''}`}>
            <div className="Text-J">
              <h2 className="StepHeading">Step  4 : Enjoy Success</h2>
              <h4>Experience satisfaction as we deliver results.</h4>
            </div>
            <div className="StepImagesContainer-J">
            <img src={customer} alt="" className="StepImages-J"/>
            </div>
          </div>
        
        </div>
      </div>
    </div>
  );
}

export default Journey;
