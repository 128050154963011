import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import "./App.css"

import Blog from './Blog';
import Home from "./Home"
import ReadMore from "./ReadMore.js";
import ViewMoreProjects from "./ViewMoreProjects";
import Demo from "./Demo"
import Update from "./Update"
import ExampleAnalytics from './ExampleAnalytics';
import video from "./imgs/video.mp4"
import Cookies from "./Cookie.js"
function App() {
 const [loading, setLoading] = useState(true);
 const [hasConsented, setHasConsented] = useState(false);

 const disableRightClick = (e) => {
    e.preventDefault();
 };
 window.addEventListener("consentGranted", () => window.clarity('consent'));

 useEffect(() => {
    const delay = setTimeout(() => {
      setLoading(false);
      ExampleAnalytics(); 
    }, 2500); 

    return () => clearTimeout(delay);
 }, []);



 const handleConsent = (consent) => {
   setHasConsented(consent);
   if (consent) {
     window.clarity('consent');
     console.log('User has given consent for cookies.');
    } else {
      console.log('User has declined cookies.');
    }
  };

 useEffect(() => {
   if (hasConsented) {
     // Optionally, you can load Clarity here if it wasn't already loaded
     // Or perform any other actions that require user consent
   }
 }, [hasConsented]);


 return (
    <div >
        <div className="App">
          <ExampleAnalytics />
           <Routes basename="/">
            <Route path="/" element={<Home />} />
             <Route path="/demo" element={<Demo />} />
             <Route path="/blog" element={<Blog />} />
             <Route path="/blog/demo" element={<Demo />} />
             <Route path="/blog/:slug" element={<ReadMore />} />
            <Route path="/ViewMoreProjects" element={<ViewMoreProjects />} />
          </Routes>
        </div>
    </div>
 );
}

export default App;
