import React,{useEffect,useState} from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import sanityClient from './Sanity.js';
import Navbar from './Navbar';
import Main from './Main';
import "./App.css"
import Services from './Services';
import ServicePhone from './ServicePhone.js';
import Showcase from './Showcase';
import Projects from './Projects';
import Team from './Team';
import Contact from './Contact';
import Fotter from './Fotter';
import Update from "./Update"
import Journey from "./Journey"
import Chatbot from './Chatbot.js';
import  initializeGoogleAnalytics  from './ExampleAnalytics';
import ReactGA from 'react-ga';
import { Helmet } from 'react-helmet';
function App() {

  const [MetaData, setMetaData] = useState([]);
  useEffect(() => {
    fetchProjects();
   }, []);
  useEffect(() => {
    initializeGoogleAnalytics();
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const fetchProjects = async () => {
    try {
      const response = await sanityClient.fetch('*[_type == "seo"]{title, description, keywords}');
      setMetaData(response[0]); // Assuming the response is an array and we want the first item
    } catch (error) {
      console.error('Error fetching SEO data from Sanity:', error);
    }
 };
const isObjectEmpty = (objectName) => {
  return Object.keys(objectName).length === 0;
};


console.log(isObjectEmpty(MetaData)); // Will log true if MetaData is empty, false otherwise
  return (
    
      <div className="App" id="home">

          <Chatbot/>
          <Update/>
          <Navbar/>
          <Main/>
          <ServicePhone/>
          <Services/>
          <Journey/>
          <Showcase/>
          <Projects/>
          <Team/>
          <Contact/>
          <Fotter/>
       
      </div>
          
       
   
  );
}

export default App;

