// Main.js
import React, { useState, useEffect } from 'react';
import './Main.css';
import Wa from "./imgs/wa2.png";


const Main = () => {
  const [currentCategory, setCurrentCategory] = useState('');
  const [isSlidingUp, setIsSlidingUp] = useState(false);

  useEffect(() => {
    // Initialize Google Analytics tracking code


    const categories = ['WEBSITES', 'APPS', 'LOGOS', 'POSTERS'];
    let index = 0;

    const interval = setInterval(() => {
      setIsSlidingUp(true);

      setTimeout(() => {
        setIsSlidingUp(false);
        setCurrentCategory(categories[index]);

        setTimeout(() => {
          setIsSlidingUp(true);

          setTimeout(() => {
            setIsSlidingUp(false);
          }, 800); // Adjust this duration for the time the category stays visible before sliding up

          index = (index + 1) % categories.length;
        }, 2000); // Adjust this duration for the time the category stays visible before sliding up
      }, 800); // Adjust this duration for the time the category stays visible before sliding up
    }, 2400); // Total duration of one cycle (4s + 0.8s + 2s + 0.8s = 7.6s)

    return () => clearInterval(interval);
  }, []);

  const handleWhatsAppClick = () => {
    const phoneNumber = '+919596575743';
    const message = encodeURIComponent("PixenX Team! 👋I am Excited for a 15-min demo call on web, app, and ad services. Let's supercharge my online presence. Ready when you are! 🚀 #PixenXDemo");
    const whatsappURL = `https://wa.me/${phoneNumber}?text=${message}`;
    window.open(whatsappURL, '_blank');
  };


  
  return (
    <div className="main-container">
      <button className="demo-call-btn" onClick={handleWhatsAppClick}>
        Get a 15-min Free Consultation Call
        <img src={Wa} className="call-logo" alt="WhatsApp Logo" />
      </button>
      <div className="text-container">
        <h3 className="h3">Captivate & Convert</h3>
        <div className="Text">
          <div className="p">Maximizing Online Potential With Affordable</div>
          <div className={`text ${isSlidingUp ? 'slide-up' : 'slide-down'}`}>
            <div className="text-animation">
              <span>{currentCategory}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Main;
