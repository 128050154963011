// Services.js

import React, { useEffect, useState } from 'react';
import './Services.css';
import Laptop from './imgs/Laptop.png';
import wdImage from './imgs/wd.jpeg';
import gdImage from './imgs/gd.jpg';
import faImage from './imgs/fa.webp';
import ccImage from './imgs/cc.jpg';
import cwImage from './imgs/cw.webp';
import adImage from './imgs/ad.jpg';

function Services() {
  const [isRotated, setIsRotated] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [selectedService, setSelectedService] = useState(null);

  useEffect(() => {
    const handleScroll = () => {
      const servicesContainer = document.querySelector('.ServicesContainer');

      if (servicesContainer) {
        const rect = servicesContainer.getBoundingClientRect();
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

        if (scrollTop >= rect.top) {
          setIsRotated(true);
          setScrollPosition(scrollTop);
        } else {
          setIsRotated(false);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    const timeoutId = setTimeout(() => {
      setIsLoaded(true); // Trigger the animation
   }, 500);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      clearTimeout(timeoutId);
    };
  }, []);

  const handleServiceClick = (serviceName) => {
    setSelectedService(serviceName);
  };

  const getImageSource = () => {
    switch (selectedService) {
      case 'Website Development':
        return wdImage;
      case 'Graphic Design':
        return gdImage;
      case 'Lead Generation':
        return faImage;
      case 'Content Creation':
        return ccImage;
      case 'Content Writing':
        return cwImage;
      case 'App Development':
        return adImage;
      default:
        return wdImage; // Default image for 'Website Development'
    }
  };

  return (
    <div className={`ServicesContainer ${isRotated ? 'rotate' : ''} ${isLoaded ? 'loaded' : ''}`} id="services">
      <img
        src={Laptop}
        className={`Laptop ${isRotated ? 'rotate' : ''}`}
        style={{ transform: `rotate(${isRotated ? -6 : 0}deg)` }}
        alt="Laptop"
      />
      <div
        className={`ServiceOverlay ${isRotated ? 'rotate' : ''}`}
        style={{ transform: `translate(-50%, -50%) rotate(${isRotated ? -6 : 0}deg)` }}
      >
        <div className="ServiceRows">
          <div className="ServiceHeading">Services We Offer</div>
          {['Website Development', 'Graphic Design', 'Lead Generation', 'Content Creation', 'Content Writing', 'App Development'].map((service) => (
            <div
              key={service}
              className={`ServiceRow ${selectedService === service ? 'selected' : ''}`}
              onClick={() => handleServiceClick(service)}
            >
              {service}
            </div>
          ))}
        </div>
        <img src={getImageSource()} className="ServiceImage" alt={`Service Image for ${selectedService}`} />
      </div>
    </div>
  );
}

export default Services;
