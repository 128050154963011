import React, { useState, useEffect } from 'react';
import "./ReadMore.css";
import { Helmet } from 'react-helmet';
import createClient from './Sanity.js'; 
import { NavLink, useParams, useNavigate } from 'react-router-dom';
import { Link } from 'react-scroll';
import BlockContent from '@sanity/block-content-to-react';
import Update from "./Update.js"

function ReadMore() {
  const [showNavbar, setShowNavbar] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const { slug } = useParams();
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate(); // Use useNavigate instead of useHistory

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowNavbar(true);
      setIsLoaded(true); 
    }, 100);

    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    createClient
      .fetch(`*[_type == "blogPost" && slug.current == $slug][0]  { title,  slug, image{asset->{ _id, url },alt }, column, isFeatured,body }`, { slug })
      .then((data) => {
        setPost(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching blog post:', error);
        setLoading(false);
      });
  }, [slug]);

  const handleClick = () => {
    // Navigate to the SingleBlogPost component with the post's slug
    navigate(`/blog/:${slug}`); // Use navigate instead of history.push
  };

  if (loading) {
    return <div className="load">Loading...</div>;
  }

  if (!post) {
    return <div className="load">Post not found</div>;
  }

  return (
    <div className="ReadMoreContainer" id="BlogReadMore">
      <Helmet>
        <title>{post.title}</title>
        <meta name="description" content={post.body} />
        <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5149282709151555" crossOrigin="anonymous"></script>
      </Helmet>
      <Update/>
      <nav className={`navbar ${showNavbar ? 'show' : ''}`}>
        <div className="logo">
          <NavLink to="/">
            <span className="brand">Pixen</span>
            <span className="highlight">X</span>
          </NavLink>
        </div>
        <NavLink to="/blog">
  
            <button className="demo-btn-blog" >Explore</button>
    
        </NavLink>
      </nav>
      <div className="RM-Blogs">
        <div className="TagsContainer">
            <div className="Tag">#Website-Development</div>
        </div>
        <h1 className="title-RMBlogs">{post.title}</h1>
        <div className="ImageAndTextBox-RMBlogs">
        <div className="Content-RMBlogs">
  
          <BlockContent blocks={post.body} projectId='gzg44yme' dataset="production" />
         
          </div>
          <div className="ImageConatiner-RMBlogs">
            <img src={post.image.asset.url} className="Image-RMBlogs" alt={post.title} />
          </div>       
        </div>
     
        <div className="Trap-Blog-Container">
            <NavLink to="/">
          <button className="Trap-Blog"  >Grow Your Business</button>
          </NavLink>
        </div>
      </div>
    </div>
  );
}

export default ReadMore;
