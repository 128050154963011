import React, { useState, useEffect } from 'react';
import { motion,AnimatePresence } from 'framer-motion';
import './ServicePhone.css';
import Phone from './imgs/phone2.png';
import wdImage from './imgs/wd.jpeg';
import gdImage from './imgs/gd.jpg';
import faImage from './imgs/fa.webp';
import ccImage from './imgs/cc.jpg';
import cwImage from './imgs/cw.webp';
import adImage from './imgs/ad.jpg';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const sliderSettings = {
  dots: true,
  infinite: true,
  speed:   1000,
  slidesToShow:   1,
  slidesToScroll:   1,
  arrows: false,
  autoplay: true, // Enable autoplay
  autoplaySpeed:  1300, // Set the autoplay speed to  2000ms (2 seconds)
  centerMode: true, // Enable center mode
  centerPadding: '15px', // Set the padding around the centered slide
};


const imagesAndHeadlines = [
  { imageSrc: wdImage, headline: 'Website Development' },
  { imageSrc: gdImage, headline: 'Graphic Design' },
  { imageSrc: faImage, headline: 'Lead Generation' },
  { imageSrc: ccImage, headline: 'Content Creation' },
  { imageSrc: cwImage, headline: 'Content Writing' },
  { imageSrc: adImage, headline: 'App Development' },
];

// ...


function ServicePhone() {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % imagesAndHeadlines.length);
    }, 4000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="ServicePhoneContainer" id="servicesPhone">
      <motion.div className="PhoneImage">
        <img src={Phone} alt="Phone" />
        <div className="SlidingServices">
        <Slider {...sliderSettings}>
            {imagesAndHeadlines.map((item, i) => (
              <div key={i} className="ServiceItem">
                <div className="weoffer">Services we offer</div>
                <img src={item.imageSrc} className="slidingImage" alt={item.headline} />
                <h3 className="sligingHeadline">{item.headline}</h3>
              </div>
            ))}
         </Slider>
        </div>
      </motion.div>
    </div>
  );
}

export default ServicePhone;