// Blog.js
import React  from 'react';
import {useState,useEffect}  from 'react';
import { NavLink } from 'react-router-dom';
import { Link } from 'react-scroll';
import { useInView } from 'react-intersection-observer';
import './Blog.css'; // Import your CSS file for styling
import FeaturedBlogImg from "./imgs/blog.jpg";
import { motion, useAnimation } from 'framer-motion';
import BI from "./imgs/blog.jpg"
import BIi from "./imgs/gd.jpg";
import  createClient  from './Sanity.js';

const Blog = () => {
  const [showNavbar, setShowNavbar] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [sanityData, setSanityData] = useState([]);

  useEffect(() => {
    createClient
      .fetch(`*[_type == "blogPost"] { title,  slug, image{asset->{ _id, url },alt }, column, isFeatured,body }`)
      .then((data) => {
        setIsLoaded(true); // Set isLoaded to true after data is fetched
        const categorizedPosts = {
          column1: [],
          column2: [],
          column3: [],
          featured: [], // Array to hold featured posts
        };
  
        data.forEach((post) => {
          if (post.isFeatured) {
            categorizedPosts.featured.push(post);
          } else {
            categorizedPosts[post.column].push(post);
          }
        });
  
        setSanityData(categorizedPosts);
      })
      .catch((error) => console.error('Error fetching Sanity data:', error));
  }, []);
  
  

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowNavbar(true);
      setIsLoaded(true); // Set isLoaded to true after the timeout
    }, 100);

    return () => clearTimeout(timeout);
  }, []);

  const controls1 = useAnimation();
  const [ref1, inView1] = useInView({
    triggerOnce: true,
  });

  useEffect(() => {
    if (inView1) {
      controls1.start({ opacity: 1, x: 0 });
    }
  }, [controls1, inView1]);

  const controls2 = useAnimation();
  const [ref2, inView2] = useInView({
    triggerOnce: true,
  });

  useEffect(() => {
    if (inView2) {
      controls2.start({ opacity: 1, y: 0 });
    }
  }, [controls2, inView2]);

  const controls3 = useAnimation();
  const [ref3, inView3] = useInView({
    triggerOnce: true,
  });

  useEffect(() => {
    if (inView3) {
      controls3.start({ opacity: 1, x: 0 });
    }
  }, [controls3, inView3]);

  const variants1 = {
    hidden: { opacity: 0, x: -300 },
  };

  const variants2 = {
    hidden: { opacity: 0, y: 50 },
  };

  const variants3 = {
    hidden: { opacity: 0, x: 300 },
  };


  //for text blogs
  const controls = useAnimation();
  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  useEffect(() => {
    if (inView) {
      controls.start({ opacity: 1, y: 0 });
    }
  }, [controls, inView]);

  const variants = {
    hidden: { opacity: 0, y: 50 },
  };

  return (
    <div className="blog-container">
      {/* Navigation Bar */}
      <nav className={`navbar-blog ${showNavbar ? 'show' : ''}`}>
        <div className="logo-blog">
          <NavLink to="/">
            <span className="brand ">Pixen</span>
            <span className="highlight ">X</span>
          </NavLink>
        </div>
        <NavLink to="demo" spy={true} smooth={true} duration={1000}>
           <button className="demo-btn-blog">Demo</button>
        </NavLink>
      </nav>

     
      
      {!isLoaded ? (
        <div className="loading-container">
          <p className="Load">Loading...</p>
        </div>
      ) : (
     
      <div className={`featured-post-Container ${isLoaded ? 'loaded' : ''}`}>
    
         <motion.div
          className="blog-header1"
          initial="hidden"
          animate={controls}
          variants={variants}
          transition={{ duration: 1, delay: 0.5 }}
          ref={ref}
        >
          Blogs
        </motion.div>
        {sanityData.featured && sanityData.featured.map((post) => (
            <div key={post._id} className="featured-post">
              <div className="post-text">
               <h1 className="blogtitle">{post.title}</h1>
               <NavLink to={`/blog/${post.slug.current}`}>
                   <button className="Rmbtn">Read More</button>
                </NavLink>
              </div>
              <div className="post-image-container">
                <img className="post-image" src={FeaturedBlogImg} alt="Featured Post" />
                </div>

           
        </div>
        ))}
      </div>
      )}

      
      <div className="more-articles">

      <h2 className="blog-header">    More Articles </h2>
   
          <div className="article-Rows">
          
            {/* Article 1 */}
            <motion.div
              className="article-Column1"
              initial="hidden"
              animate={controls1}
              variants={variants1}
              transition={{ duration: 1 }}
              ref={ref1}
        >
           {sanityData.column1 && sanityData.column1.map((post) => (
               <div key={post._id} className="article">
               <img src={post.image.asset.url} alt={post.title} className="ImgBlog"/>
               <div className="info">
               <div className="ArticleName">{post.title}</div>   
               <NavLink to={`/blog/${post.slug.current}`}>                  
                 <button className="RmLink" >Read More</button>
                </NavLink>
          </div>     
        </div>
      ))}
       
         </motion.div>


         <motion.div
             className="article-Column2"
             initial="hidden"
             animate={controls2}
             variants={variants2}
             transition={{ duration: 1 }}
             ref={ref2}
        >
    {sanityData.column2 && sanityData.column2.map((post) => (
         <div key={post._id} className="article">
         <img src={post.image.asset.url} alt={post.title} className="ImgBlog"/>
         <div className="info">
         <div className="ArticleName">{post.title}</div>   
         <NavLink to={`/blog/${post.slug.current}`}>                  
           <button className="RmLink" >Read More</button>
          </NavLink>
    </div>     
  </div>
      ))}
          </motion.div>
       
           

           
         <motion.div
           className="article-Column3"
           initial="hidden"
           animate={controls3}
           variants={variants3}
           transition={{ duration: 1 }}
           ref={ref3}
        >
    {sanityData.column3 && sanityData.column3.map((post) => (
        <div key={post._id} className="article">
        <img src={post.image.asset.url} alt={post.title} className="ImgBlog"/>
        <div className="info">
        <div className="ArticleName">{post.title}</div>   
        <NavLink to={`/blog/${post.slug.current}`}>                  
          <button className="RmLink" >Read More</button>
         </NavLink>
   </div>     
 </div>
      ))}
           </motion.div>
    

       
          </div>
       
   
        {/* Repeat the structure for additional rows of articles */}
      </div>
    
    </div>
  );
};

export default Blog;