// ExampleAnalytics.js
export default function initializeGoogleAnalytics() {
    // Google Analytics tracking code
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag('js', new Date());
    gtag('config', 'G-WJPCK8R7N2');
  }
  