import React, { useEffect, useRef, useState } from 'react';
import './Contact.css'; // Import your CSS file for styling
import WhatsAppLogo from './imgs/wa.jpg';

function Contact() {
  const contactRef = useRef(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [brandName, setBrandName] = useState('');
  const [contactNo, setContactNo] = useState('');
  const [showPopup, setShowPopup] = useState(false);



  const handleWhatsAppClick = () => {
    const phoneNumber = '+919596575743';
    const message = encodeURIComponent("Hi PixenX team! 👋 I am Interested in a 15-min demo call to explore your stellar web, app, and ad services. 🌐📱💡 Let's discuss how you can amplify my online presence. Ready when you are! 🚀 #DigitalInnovation #PixenXDemo");
    const whatsappURL = `https://wa.me/${phoneNumber}?text=${message}`;
    window.open(whatsappURL, '_blank');
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    window.alert("Form Submitted")


    // Replace this URL with your backend URL for form submission
    const url = 'https://script.google.com/macros/s/AKfycbyYyK8-sAZNmmghfSn89j2WEaGG0jAq3yGV5Xhz89S-sKfg7i_FkSl9WpAxh-C4LKGy/exec';

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({
          'Brand-Name': brandName,
          'ContactNo': contactNo,
        }).toString(),
      });

      if (response.ok) {
        setShowPopup(true);
      } else {
        console.error('Error submitting form:', response.statusText);
      }
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };




  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setIsLoaded(true);
        }
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.5,
      }
    );

    if (contactRef.current) {
      observer.observe(contactRef.current);
    }

    return () => {
      if (contactRef.current) {
        observer.unobserve(contactRef.current);
      }
    };
  }, []);

 

  return (
    <div className="contact" id="contact">
      <div
        ref={contactRef}
        className={`contact-container ${isLoaded ? 'loaded' : ''}`}
      >
        <div className="contact-content">
          <div className="left-section">
            <p className="bold-text">
              Book a 15 min Free <br /> Consultation Call
            </p>
            <p className="line">
              We'll take the work out of elevating your virtual experiences
            </p>
            <button className="whatsapp-btn"  onClick={handleWhatsAppClick}>
              <p className="BtnText">Contact Us on WhatsApp </p>
              <img src={WhatsAppLogo} alt="WhatsApp Logo" />
            </button>
          </div>
          <div className="right-section">
          <form onSubmit={handleSubmit}>
                    <label>
                    <input
                        className="d-input"
                        type="text"
                         placeholder="Brand Name"
                        value={brandName}
                         onChange={(e) => setBrandName(e.target.value)}
                      />
                    </label>
                     <label>
                      <input
                          type="tel"
                          className="d-input"
                          placeholder="Phone No."
                          value={contactNo}
                          onChange={(e) => {
                            // Use a regular expression to allow only numeric values
                            const numericValue = e.target.value.replace(/\D/g, '');
                            setContactNo(numericValue);
                          }}
                          pattern="[0-9]*"  // This allows only numeric values
                         />
                      </label>
                       <button type="d-submit" className="submit-btn"  >
                          Submit
                      </button>
                  
                    </form>
                 
    
          </div>
        </div>
      </div>

      {showPopup && (
        <div className="popup">
          <div className="popup-content">
            <span className="close" onClick={() => setShowPopup(false)}>
              &times;
            </span>
            <br />
            <p style={{ color: 'black' }}>
              Form submitted successfully!<br /> Our team will contact you shortly.<br />
              <button className="wabtn"  onClick={handleWhatsAppClick}>Contact Us on Whatsapp</button>
            </p>
          </div>
        </div>
      )}
    </div>
  );
}

export default Contact;
