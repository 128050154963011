import React, { useEffect, useRef, useState } from 'react';
import './Showcase.css';
import Cup from "./imgs/cup.png";
import Tick from "./imgs/Tick.png";
import Client from "./imgs/clients.png";

function Showcase() {
 const showcaseRef = useRef(null);
 const [isLoaded, setIsLoaded] = useState(false);
 const [clientCount, setClientCount] = useState(0);
 const [projectCount, setProjectCount] = useState(0);
 const [experienceYears, setExperienceYears] = useState(0);

 useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && !isLoaded) {
          setIsLoaded(true);
          startAnimations();
        }
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.5,
      }
    );

    if (showcaseRef.current) {
      observer.observe(showcaseRef.current);
    }

    return () => {
      if (showcaseRef.current) {
        observer.unobserve(showcaseRef.current);
      }
    };
 }, [isLoaded]);

 const startAnimations = () => {
    let clientInterval, projectInterval, experienceInterval;
    const totalTime = 10000; // Total time for the countup in milliseconds
    const clientSteps = 300; // Number of steps for the client count
    const projectSteps = 600; // Number of steps for the project count
    const experienceSteps = 3; // Number of steps for the experience years

    const clientIntervalDuration = totalTime / clientSteps;
    const projectIntervalDuration = totalTime / projectSteps;
    const experienceIntervalDuration = totalTime / experienceSteps;

    clientInterval = setInterval(() => {
      setClientCount((prevCount) => {
        if (prevCount >= 300) {
          clearInterval(clientInterval);
          return 300;
        }
        return prevCount + 1;
      });
    }, clientIntervalDuration);

    projectInterval = setInterval(() => {
      setProjectCount((prevCount) => {
        if (prevCount >= 600) {
          clearInterval(projectInterval);
          return 600;
        }
        return prevCount + 1;
      });
    }, projectIntervalDuration);

    experienceInterval = setInterval(() => {
      setExperienceYears((prevCount) => {
        if (prevCount >= 3) {
          clearInterval(experienceInterval);
          return 3;
        }
        return prevCount + 1;
      });
    }, experienceIntervalDuration);

    return () => {
      clearInterval(clientInterval);
      clearInterval(projectInterval);
      clearInterval(experienceInterval);
    };
 };
 useEffect(() => {
  let clientInterval, projectInterval, experienceInterval;
  const totalTime = 10000; // Total time for the countup in milliseconds
  const clientSteps = 300; // Number of steps for the client count
  const projectSteps = 600; // Number of steps for the project count
  const experienceSteps = 3; // Number of steps for the experience years

  const clientIntervalDuration = totalTime / clientSteps;
  const projectIntervalDuration = totalTime / projectSteps;
  const experienceIntervalDuration = totalTime / experienceSteps;
  // Start animations when the component mounts
  startAnimations();

  // Return a cleanup function to clear intervals when the component unmounts
  return () => {
    clearInterval(clientInterval);
    clearInterval(projectInterval);
    clearInterval(experienceInterval);
  };
}, []); // Empty dependency array ensures this effect runs once on mount


 return (
    <div ref={showcaseRef} className={`showcase-container ${isLoaded ? 'loaded' : ''}`}>
      <div className="showcase-item L">
        <img src={Client} alt="" />
        <p>
          {clientCount}+
          <br/> Clients
        </p>
      </div>
      <div className="showcase-item M">
        <img src={Tick} alt="" />
        <p>
          {projectCount}+
          <br/> Projects
        </p>
      </div>
      <div className="showcase-item R">
        <img src={Cup} alt="" />
        <p>
      Years of   <br/>Experience
        </p>
      </div>
    </div>
 );
}

export default Showcase;
