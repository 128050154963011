import React, { useEffect, useRef, useState } from 'react';
import sanityClient from './Sanity.js'; // Import the Sanity client configuration
import './Project.css';
import { NavLink } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';




const sliderSettings = {

  dots: false,
  infinite: true,
  speed:  700,
  slidesToShow:  3,
  slidesToScroll:  1,
  initialSlide:  0,
  arrows: false,

  responsive: [
    {
      breakpoint:  2500,
      settings: {
        slidesToShow:  4,
        slidesToScroll:  1,
        infinite: true,
        dots: true,
        arrows: true,
      }
    },
    {
      breakpoint:  1624,
      settings: {
        slidesToShow:  3,
        slidesToScroll:  1,
        infinite: true,
        dots: true,
        arrows: true,
        autoplay: true, // Enable autoplay
        autoplaySpeed:  3000, // Set the autoplay speed to  2000ms (2 seconds)
   
      }
    },
    {
      breakpoint:  1024,
      settings: {
        slidesToShow:  2,
        slidesToScroll:  1,
        infinite: true,
        dots: true,
        arrows: true,
        centerMode: true, // Enable center mode
        centerPadding: '50px', // Set the padding around the active slide,
        autoplay: true, // Enable autoplay
        autoplaySpeed:  3000, // Set the autoplay speed to  2000ms (2 seconds)
      }
    },
    {
      breakpoint:  600,
      settings: {
        slidesToShow:  1,
        slidesToScroll:  1,
        initialSlide:  2,
        infinite: true,      
        arrows: false,
        centerMode: true, // Enable center mode
        centerPadding: '50px', // Set the padding around the active slide
        autoplay: true, // Enable autoplay
        autoplaySpeed:  2500, // Set the autoplay speed to  2000ms (2 seconds)
      }
    }
  ]
};

function Projects() {
  const projectCollectionRef = useRef(null);
  const [isProjectCollectionLoaded, setIsProjectCollectionLoaded] = useState(false);
  const [projects, setProjects] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          fetchProjects(); // Fetch projects when the component is loaded
          setIsProjectCollectionLoaded(true);
          observer.disconnect(); // Stop observing once it's loaded
        }
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.5,
      }
    );

    if (projectCollectionRef.current) {
      observer.observe(projectCollectionRef.current);
    }

    return () => {
      if (projectCollectionRef.current) {
        observer.disconnect();
      }
    };
  }, []);

  const fetchProjects = async () => {
    try {
      const response = await sanityClient.fetch('*[_type == "mainProjexts"] { name,visitLink, logo {asset->{ _id, url },alt }}');
      setProjects(response);
      setIsLoading(false); // Data is fetched, stop showing loading
    } catch (error) {
      console.error('Error fetching projects from Sanity:', error);
      setIsLoading(false); // Also stop showing loading in case of error
    }
 };

  return (
    <div ref={projectCollectionRef} className={`projects-container ${isProjectCollectionLoaded ? 'loaded' : ''}`} id="projects">
      <h2>Our Projects</h2>
      {isLoading ? (
        <div className="LoadingText">Loading...</div>
      ) : (
        <Slider {...sliderSettings}>
          {projects.map((project) => (
            <div key={project._id} className="project-item">
              <div className="Project-Card">
                <div className="logo-Projects">
                  <img src={project.logo && project.logo.asset && project.logo.asset.url ? project.logo.asset.url : ''} className="img" alt={project.name} />
                </div>
                <a href={project.visitLink} target="_blank" rel="noopener noreferrer" className="visit">
                  Visit
                </a>
              </div>
              <p>{project.name}</p>
            </div>
          ))}
        </Slider>
      )}
      <NavLink to="/ViewMoreProjects">
        <button className="view-more-button">View More Projects</button>
      </NavLink>
    </div>
  );
}

export default Projects;
