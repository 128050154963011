import createClient from './Sanity.js';
import imageUrl from '@sanity/image-url';
import React, { useEffect, useRef, useState } from 'react';
import './Team.css';
import linkedin from "./imgs/Llinkedin.png"
import { motion, AnimatePresence, useAnimation } from 'framer-motion';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const sliderSettings = {
  dots: false,
  infinite: true,
  speed:  600,
  slidesToShow:  1,
  slidesToScroll:  1,
  centerMode: true,
  centerPadding: '20px',
  autoplay: true,
  autoplaySpeed: 2500,
  arrows: false,
  responsive: [
    {
      breakpoint:  1000,
      settings: {
        slidesToShow:  2,
        slidesToScroll:  1,
        infinite: true,
       
      }
    },
    {
      breakpoint:  500,
      settings: {
        slidesToShow:  1,
        slidesToScroll:  1,
        infinite: true,
       
      }
    },] // This line disables the arrows
  // Add any other settings you want to customize the slider
};

function Team() {
  const [text, setText] = useState('');
  const [isTypingAnimationStarted, setIsTypingAnimationStarted] = useState(false);
  const typingAnimeRef = useRef(null);
  const [leadingTeamData, setLeadingTeamData] = useState([]);
  const [labourersData, setLabourersData] = useState([]);
  const [labourerIndex, setLabourerIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  // Effect to update the screen width when the window is resized
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [])
    
   

  useEffect(() => {
    // Fetch Leading Team data from Sanity
    createClient
    .fetch(`
      *[_type == "teamMember" && category == "LeadingTeam"] {
        name, designation, teamReviewHeader, teamReviewText, image {asset->{ _id, url }}, linkedinLink
      }`)
    .then((data) => setLeadingTeamData(data))
    .catch((error) => console.error('Error fetching Leading Team data:', error));

    // Fetch Labourers data from Sanity
    createClient
    .fetch(`*[_type == "teamMember" && category == "Labourers"] {
       name, designation, teamReviewHeader, teamReviewText, image{asset->{ _id, url }}, linkedinLink
    }`)
    .then((data) => {
       setLabourersData(data);
       setIsLoading(false); // set loading to false after data is fetched
    })
    .catch((error) => {
       console.error('Error fetching Labourers data:', error);
       setIsLoading(false); // also set loading to false in case of error
    });
   
   }, []);



  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && !isTypingAnimationStarted) {
          setIsTypingAnimationStarted(true);
          startTypingAnimation();
        }
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.5,
      }
    );

    if (typingAnimeRef.current) {
      observer.observe(typingAnimeRef.current);
    }

    return () => {
      if (typingAnimeRef.current) {
        observer.unobserve(typingAnimeRef.current);
      }
    };
  }, [isTypingAnimationStarted]);

  const startTypingAnimation = () => {
    const fullText = "and every collaboration is a masterpiece.";

    let index = 0;
    const typingInterval = setInterval(() => {
      setText(fullText.substring(0, index));
      index++;

      if (index > fullText.length) {
        clearInterval(typingInterval);
      }
    }, 100);
  };

  return (
    <div className="team-container" id="team">
       
      <div className="h2">Our Team</div>
      <div className="quote">
        Welcome to the powerhouse behind the scenes!<br/> Immerse yourself in the energy of our diverse team,<br/> where every idea is a spark, <br/>
        {text}
        <span ref={typingAnimeRef} className={`cursor ${isTypingAnimationStarted ? 'blink' : ''}`}></span>
      </div>
     
      <div className="team-members">
      {screenWidth >=  1000 ? (
        <div className="LeadingTeam">   
         {leadingTeamData.map((member, index) => (
               <div key={index} className="team-member">          
               <div className="TeamImageReviewContainer">
               <div className="TeamImageContainer">
                <img
                  src={member.image && member.image.asset && member.image.asset.url ? member.image.asset.url : ''}
                  alt={`Team Member ${index + 1}`}
                  className="lead-img"
               />
              
               </div>
             </div>
             <div className="p">{member.name} <br/> {member.designation}</div>
           </div>
          ))}
       </div>
          ) : (
            // JSX for screen width less than  1000px
            <div className="LeadingTeam-Mobile">
                   <Slider {...sliderSettings}>
 {leadingTeamData.map((member, index) => (
    <div key={index} className="team-member-Labourers">
      <div className="TeamImageReviewContainer-L">
        <div className="TeamImageContainer-L">
          <img
            src={member.image && member.image.asset && member.image.asset.url ? member.image.asset.url : ''}
            alt={`Team Member ${index + 1}`}
            className="lead-img"
          />
        
        </div>
      </div>
      <div className="p">{member.name} <br/> {member.designation} </div>
      
    </div>
  ))}
  </Slider>
            </div>
          )}
        <div className="SecondDiv">
        <div className="Labourers-Container">
        <div className="Labourers">  
        
              <Slider {...sliderSettings}>
 {labourersData.map((member, index) => (
    <div key={index} className="team-member-Labourers">
      <div className="TeamImageReviewContainer-L">
        <div className="TeamImageContainer-L">
          <img
            src={member.image && member.image.asset && member.image.asset.url ? member.image.asset.url : ''}
            alt={`Team Member ${index + 1}`}
            className="lead-img"
          />
        
        </div>
      </div>
      <div className="p">{member.name} <br/> {member.designation} </div>
      
    </div>
  ))}
  </Slider>
         
      
       </div>
       </div>

       <div className="video-container">
       <div className="youtube-video-placeholder">
         <iframe src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2Flivethetypewriter%2Fvideos%2F1456262928260192%2F&show_text=false&width=560&t=0"
             width="100%"
             height="100%"
             style={{ border: 'none', overflow: 'hidden' ,borderRadius: "20px"}}  
             scrolling="no"
             frameborder="0"
             allowfullscreen="true"
             allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
             allowFullScreen="true">
         </iframe>
       </div>
       </div>

       </div>
     
      </div>
 
      </div>
  );
}

export default Team;