// Footer.js

import React from 'react';
import './Fotter.css'; // Create a CSS file for styling
import { Link } from 'react-scroll';
import {NavLink} from 'react-router-dom'
function Footer() {
  const scrollTo = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };
  return (
    <div className="footer-container">
      <div className="row">
      <div className="footer-column">
        <h4>Follow Us</h4>
        <ul>
          <li onClick={()=> window.location.href ="https://www.instagram.com/pixenx"}>Instagram</li>
          <li onClick={()=> window.location.href ="https://www.facebook.com/profile.php?id=100090464538254&mibextid=ZbWKwL"}>Facebook</li>
          <li onClick={()=> window.location.href ="https://www.linkedin.com/company/pixenx/"}>LinkedIn</li>
          <li onClick={()=> window.location.href ="https://wa.me/message/UPHOGR5D64ZZC1/"}>WhatsApp</li>
        </ul>
      </div>
      <div className="footer-column">
        <h4>Explore</h4>
        <ul>
          <li onClick={() => window.location.href ="/blog"} >
            Our Blogs
          </li>
          <li onClick={() => scrollTo('projects')}>
            Our Projects
          </li>
          <li onClick={() => scrollTo('team')}>
            Our Team
          </li>
      
        </ul>
      </div>
      </div>
      <div onClick={() => window.location.href = "https://www.google.com/search?q=pixenx&rlz=1C1ZKTG_enIN822IN896&oq=&gs_lcrp=EgZjaHJvbWUqCQgBECMYJxjqAjIJCAAQIxgnGOoCMgkIARAjGCcY6gIyCQgCECMYJxjqAjIJCAMQIxgnGOoCMgkIBBAjGCcY6gIyCQgFECMYJxjqAjIJCAYQIxgnGOoCMgkIBxAjGCcY6gLSAQoxMDYyNDRqMGo3qAIIsAIB&sourceid=chrome&ie=UTF-8"}>

      <h4> Copyright © 2023 by KR Knowledge Realization Private Limited. </h4>
      </div>
   
    </div>
  );
}

export default Footer;
